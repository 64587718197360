<template>
  <div class="aside">
    <el-menu
      :default-active="content"
      class="menu"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="studentsList">
        <i class="el-icon-s-data"></i>
        <span slot="title">{{ $t("courses.students_list") }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Backend",
  props: {
    content: {
      type: String
    }
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      this.$router.replace({
        name: key
      });
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.aside {
  position: fixed;
  height: 100%;
  width: 200px;
}
.menu {
  height: 100%;
}
</style>
